import DIcon from '@components/DIcon/DIcon';
import '@fragments/SLink';
import { graphql } from 'gatsby';
import React from 'react';

export default function DConnect ({ data }) {
    const { contactTitle, contactLinks, platformTitle, platformLinks } = data;

    const contactMethods = contactLinks.map((link, index) => {
        if (link.title === 'mail') return <LinkIcon to={`mailto:${link.to}`} link={link} key={index} icon="email" />;
        return <LinkIcon to={link.to} target={link.target} key={index} icon={link.title} />;
    });

    const platforms = platformLinks.map((link, index) => (<LinkIcon to={link.to} target={link.target} key={index} icon={link.title} />));


    return (
        <div className="d-connect d-flex flex-column align-center justify-center vh-100-header w-100 bg-primary">
            <Title text={contactTitle} />
            <LinksWrap className="mb-8 mb-sm-4">{contactMethods}</LinksWrap>
            <Title text={platformTitle} />
            <LinksWrap>{platforms}</LinksWrap>
        </div>
    )
}

function Title ({ text, className }) {
    return <div className={`sub-title less-thin primary color-white text-center mb-4 mb-sm-2 ${className || ''}`}>{text}</div>;
}

function LinksWrap ({ children, className }) {
    return <div className={`d-flex align-center ${className || ''}`}>{children}</div>;
}

function LinkIcon ({ to, target, icon, className, ...props }) {
    return <a href={to} target={target} className={`mx-2 ${className || ''}`} {...props}><DIcon icon={icon} className="color-white" /></a>;
}

export const query = graphql`
    fragment SConnect on DSTRAPI_ComponentPagePartsConnect {
        contactTitle
        contactLinks {
            ... SLink
        }
        platformTitle
        platformLinks {
            ... SLink
        }
    }`