import DGridImageTile from '@components/DGridImageTile/DGridImageTile';
import '@fragments/SImage';
import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import './DImageCategoryGrid.scss';

export default function DImageCategoryGrid({ data, dimensions }) {
    const { title, imageCategoriesComponent } = data;

    const allCategoryImages = useMemo(() => {
        return imageCategoriesComponent.map(({ imageCategory: cat }, index) => ({
            gatsbyImage: getImage(cat.featuredImage.imageFile),
            alternativeText: cat.featuredImage.alternativeText,
            index,
            slug: cat.slug,
            title: cat.title
        }));
    }, [imageCategoriesComponent]);

    const masornyTiles = allCategoryImages.map((image, index) =>
        <div className="col-4 col-sm-6">
            <Link to={`/${image.slug}`} key={`d-image-grid-image-${index}`} state={{ canGoBack: true }}>
                <DGridImageTile gatsbyImage={image.gatsbyImage}
                    alternativeText={image.alternativeText} tileTitle={image.title} />
            </Link>
        </div>
    );

    return (
        <div className="d-image-category-grid w-100 d-flex justify-center">
            <div className="category-grid-wrap">
                {title && <h2 className="big-title color-primary--darken silver-belly text-center">{title}</h2>}
                <div className="row">
                    {masornyTiles}
                </div>
            </div>
        </div>
    )
}

DImageCategoryGrid.defaultProps = {
    dimensions: { xs: 6, sm: 6, md: 4, lg: 4 }
}

export const query = graphql`
    fragment SImageCategoryGrid on DSTRAPI_ComponentImageCategoryGrid {
        title
        imageCategoriesComponent {
            imageCategory {
                slug
                title
                featuredImage {
                    ... SImage
                }
            }
        }
    }`