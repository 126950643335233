import DAbout from '@components/DAbout/DAbout';
import DAnchor from '@components/DAnchor/DAnchor';
import DApp from '@components/DApp/DApp';
import DConnect from '@components/DConnect/DConnect';
import DFooter from '@components/DFooter/DFooter';
import DHeader from '@components/DHeader/DHeader';
import DHero from '@components/DHero/DHero';
import DImageCategoryGrid from '@components/DImageCategoryGrid/DImageCategoryGrid';
import DImageGrid from '@components/DImageGrid/DImageGrid';
import DMain from '@components/DMain/DMain';
import DMdContent from '@components/DMdContent/DMdContent';
import DSeo from '@components/DSeo/DSeo';
import '@fragments/SMdContent';
import { graphql } from 'gatsby';
import React from 'react';

export default function Page ({ data }) {
    const { dStrapi } = data;
    const { pageBySlug, footer } = dStrapi;

    const page = pageBySlug
    const content = [];
    page.content.forEach((current, index) => {
        if (current.__typename === 'DSTRAPI_ComponentPagePartsHero')
            content.push(<DHero key={index} data={current}></DHero>);
        if (current.__typename === 'DSTRAPI_ComponentImageCategoryGrid')
            content.push(<DImageCategoryGrid key={index} data={current}></DImageCategoryGrid>);
        if (current.__typename === 'DSTRAPI_ComponentPagePartsAbout')
            content.push(<DAbout key={index} data={current}></DAbout>);
        if (current.__typename === 'DSTRAPI_ComponentPagePartsConnect')
            content.push(<DConnect key={index} data={current}></DConnect>);
        if (current.__typename === 'DSTRAPI_ComponentGeneralMdContent')
            content.push(<DMdContent key={index} content={current.content}></DMdContent>);
        if (current.__typename === 'DSTRAPI_ComponentImageImageGrid')
            content.push(<DImageGrid key={index} data={current}></DImageGrid>);
        if (current.__typename === 'DSTRAPI_ComponentGeneralAnchor')
            content.push(<DAnchor key={index} anchorId={current.anchorId}></DAnchor>);
    });

    return (
        <DApp>
            <DSeo title={page.metaTitle} otherTags={page.meta} />
            <DHeader />
            <DMain>
                {content}
            </DMain>
            <DFooter data={footer} />
        </DApp>
    )
}

export const query = graphql`
query getPagesData($slug: String!) {
    dStrapi {
        ... SFooter
        pageBySlug(slug: $slug) {
            slug
            title
            metaTitle
            meta {
                name
                content
            }
            content {
                __typename
                ... SHero
                ... SImageCategoryGrid
                ... SAbout
                ... SConnect
                ... SMdContent
                ... SAnchor
            }
        }
    }
}`