import { graphql } from 'gatsby';
import React from 'react';
import './DAnchor.scss';

export default function DAnchor ({ anchorId }) {
    return (<div className="d-anchor" id={anchorId}></div>)
}

export const query = graphql`
    fragment SAnchor on DSTRAPI_ComponentGeneralAnchor {
        anchorId
    }`