import DDivider from '@components/DDivider/DDivider';
import { graphql } from 'gatsby';
import React from 'react';
import './DHero.scss';

export default function DHero({ data }) {
    const { subTitle } = data;
    return (
        <div className="d-hero vh-100-header d-flex flex-column justify-center align-center">
            <div className="d-flex flex-column align-center">
                <h1 className="big-title silver-belly color-primary--darken text-center">Darya Averdieck</h1>
                <DDivider />
                {subTitle && <p className="sub-title thin color-primary--darken text-uppercase">{subTitle}</p>}
            </div>
        </div>
    )
}

export const SHero = graphql`
    fragment SHero on DSTRAPI_ComponentPagePartsHero {
        subTitle
    }`