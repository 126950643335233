import DDivider from '@components/DDivider/DDivider';
import DMdContent from '@components/DMdContent/DMdContent';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import './DAbout.scss';

export default function DAbout({ data: { title, description, profileImage: { imageFile, alternativeText } } }) {
    return (
        <div className="d-about vh-100-min d-flex flex-column justify-center align-center py-2 pt-4">
            <div className="about-wrap d-flex flex-column align-center">
                {title && <h2 className="big-title color-primary--darken silver-belly">{title}</h2>}
                <GatsbyImage className="profile-image rounded-circle my-2" image={getImage(imageFile)} alt={alternativeText} imgStyle={{ objectFit: `cover` }} />
                <DDivider />
                <DMdContent className="text-center px-1" content={description} />
            </div>
        </div>
    )
}

export const query = graphql`
    fragment SAbout on DSTRAPI_ComponentPagePartsAbout {
        title
        description
        profileImage {
            url
            alternativeText
            caption
            imageFile {
                childImageSharp {
                    gatsbyImageData(width:200, height: 200)
                }
            }
        }
    }`